import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import React from "react";
import Header from "../components/header";
// import "./styles.css";

registerCoreBlocks();
const SignUp = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome-screen",
              attributes: {
                label:
                  "Let's get you started with the future of payments today",
                description: "It takes less than 5 minutes!",
                attachment: {
                  type: "image",
                  url: "https://enbank.me/image-1867@2x.png",
                },
                attachmentMaxWidth: "200px",
              },
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "Let's start with your name",
              },
            },

            {
              name: "email",
              id: "email",
              attributes: {
                required: true,
                label: "Hey {{field:name}} 👋 Please give us your email:",
              },
            },
            {
              name: "statement",
              id: "g91imf1023",
              attributes: {
                label:
                  "Thanks! We will just ask you a few questions about your business and you'll be done in no time!",
                buttonText: "Continue",
                quotationMarks: true,
              },
            },
            {
              name: "short-text",
              id: "business-name",
              attributes: {
                required: true,
                label: "What's the name of your business?",
              },
            },
            {
              name: "number",
              id: "business-uic",
              attributes: {
                required: true,
                label: "What is {{field:business-name}}'s registration number?",
                description:
                  "Please enter your company's UIC as it appers on your record from the national tax authority/company house. It should only consist of numbers",
              },
            },
            {
              name: "number",
              id: "phone-number",
              attributes: {
                required: true,
                label: "What is your phone number?",
                description: "Please enter your phone number.",
              },
            },
            {
              name: "multiple-choice",
              id: "business-roles",
              attributes: {
                required: true,
                multiple: true,
                verticalAlign: true,
                label: "Please select all the roles that apply to you.",
                choices: [
                  {
                    label: "I own more than 25% (or have controlling interest)",
                    value: "has-25-percent",
                  },
                  {
                    label: "I am a director (CEO, CTO, etc.)",
                    value: "is-director",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "business-volume",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label:
                  "What is your current payment processing volume? (per year)",
                choices: [
                  {
                    label: "My business is new",
                    value: "new-business",
                  },
                  {
                    label: "Less than €25,000",
                    value: "less-25k",
                  },
                  {
                    label: "€25,000-€100,000",
                    value: "25k-100k",
                  },
                  {
                    label: "€100,000-€250,000",
                    value: "100k-250k",
                  },
                  {
                    label: "€250,000-€1,000,000",
                    value: "250k-1mil",
                  },
                  {
                    label: "More than €1,000,000",
                    value: "look-at-me-im-rich",
                  },
                ],
              },
            },
          ],
          messages: {
            "block.defaultThankYouScreen.label":
              "Thanks for signing up! We will contact you today to give you a quote and get started!",
          },
          settings: {
            animationDirection: "horizontal",
            disableWheelSwiping: false,
            disableNavigationArrows: true,
            disableProgressBar: true,
          },
          theme: {
            font: "Inter",
            // logo: {
            //   src: "https://enbank.me/image-1867@2x.png",
            // },
            questionsColor: "#000",
            answersColor: "#000",
            buttonsBgColor: "#FFB84F",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={async (data, { completeForm, setIsSubmitting }) => {
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
          }, 500);

          try {
            const ans = await fetch("https://pay.enbank.me/merchants/submit", {
              body: JSON.stringify(data),
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
              },
              method: "POST",
            });
          } catch (error) {
            console.log("kur", error);
          }
        }}
      />
    </div>
  );
};

export default SignUp;
